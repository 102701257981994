import { Component } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../core/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from '../core/services/config.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  submitted: boolean = false;
  loginForm!: UntypedFormGroup;
  versionCode: string = "";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.checkToken();
    this.versionCode = this.configService.appConfig.version;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    this.configService.loadingDisplay(true);
    this.authService.login(this.loginForm.value).subscribe({
      next: (data: any) => {
        this.configService.loadingDisplay(false);
        this.submitted = false;

        if (data.status == 200) {
          this.loginForm.reset();
          this.cookieService.set("adToken", data.data.token, 7);
          //this.configService.showAlert('', data.message);
          this.router.navigate(['/dashboard']);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.configService.loadingDisplay(false);
        if (error.error && error.error.messages) {
          const errorMessage = error.error.messages;
          this.configService.showError(errorMessage.status, errorMessage.message);
          this.submitted = false;
        }
      }
    });
  }

  checkToken() {
    var token = this.cookieService.get('adToken');
    if (token.length > 0) {
      this.authService.checkToken(token).subscribe({
        next: (data: any) => {
          if (data.status == 200) {
            this.router.navigate(['/dashboard']);
          } else {
            this.cookieService.delete('adToken');
          }
        },
        error: (error: any) => {
          this.cookieService.delete('adToken');
        }
      });
    }
  }
}
